import {cacheAdapterEnhancer} from "axios-extensions"
import LRU from "lru-cache"

export default function ({$axios, ssrContext, app, store, route}) {
    const cacheActiveEnv = process.env.CACHE_ENV
    if (cacheActiveEnv && !['false', 'no', 'nao'].includes(route.query.cache)) {
        const defaultCache = process.server ? ssrContext.$axCache : new LRU({ttl: 1000 * 60 * 2, ttlAutopurge: true})
        const defaults = $axios.defaults
        defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
            enabledByDefault: false,
            cacheFlag: "cache",
            defaultCache
        })
    }
    if (process.client && store.getters.isAuth) {
        $axios.setToken(store.state.auth.access_token, 'Bearer');
    }
    $axios.onError(error => {
        const code = parseInt(error.response && error.response.status)
        if (code === 401) {
            if (process.client && store.getters.isAuth) {
                store.dispatch('logout')
            }
        }
    })
}
