const cookieparser = process.server ? require('cookieparser') : undefined
const Cookie = process.client ? require('js-cookie') : undefined
import wdconfig from '~/data/config.json';
import {merge} from 'lodash';

export const getters = {
    isAuth: state => {
        return !!state.auth.access_token
    },
    getWdConfig: state => {
        return state.config
    },
    cssTheme: state => {
        let theme = '';
        try {
            if (state.config) {
                for (let prop in state.config.theme) {
                    theme += `--${prop}: ${state.config.theme[prop]};`
                }
            }
        } catch (e) {
            console.error(e)
        }
        return theme
    },
    isMaintenanceMode: state => {
        return !!state.maintenance_mode
    }
}
export const state = () => {
    return {
        config: wdconfig,
        categories: {},
        menu_site: [],
        menu_footer: [],
        menu_showcase: [],
        maintenance_mode: false,
        auth: {
            access_token: '',
            person_name: ''
        }
    }
}
export const mutations = {
    setConfig(state, config) {
        state.config = merge(wdconfig, config)
    },
    setCategories(state, categories) {
        state.categories = categories
    },
    setMenuFooter(state, menu) {
        state.menu_footer = menu
    },
    setMenuSite(state, menu) {
        state.menu_site = menu
    },
    setMenuShowcase(state, menu) {
        state.menu_showcase = menu
    },
    setMaintenanceMode(state, status) {
        state.maintenance_mode = status
    },
    setAuth(state, auth = {
        access_token: '',
        person_name: ''
    }) {
        state.auth = auth
    },
}
export const actions = {
    async nuxtServerInit({commit, state, dispatch}, {req, app, error}) {
        try {
            await dispatch('getConfig')
        } catch (e) {
            console.error(e)
            error(e)
        }
        try {
            await Promise.all([
                dispatch('getCategories'),
                dispatch('getMenuSite'),
                dispatch('getMenuFooter'),
                dispatch('getMenuShowcase'),
            ]);
        } catch (e) {
            console.error(e)
        }
        let auth = {
            access_token: '',
            person_name: ''
        }
        if (req.headers.cookie) {
            const parsed = cookieparser.parse(req.headers.cookie)
            try {
                auth = JSON.parse(parsed.auth)
            } catch (err) {
                // No valid cookie found
            }
        }
        commit('setAuth', auth)
    },
    logout({commit}) {
        this.$axios.$get('/?logout=sim')
        commit('setAuth', {
            access_token: '',
            person_name: ''
        })
        Cookie.remove('auth')
    },
    async getConfig({ commit }) {
        try {
            const res = await this.$axios.$get('index.php?p=controller_settings_general_api_v1');
            if (res.status === 'success' && res.data && res.data.config) {
                commit('setConfig', res.data.config);
            } else {
                throw new Error('Configuração não encontrada');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    async getCategories({ commit }) {
        try {
            const res = await this.$axios.$get(`api/v1/ecommerce/categories`, { cache: true });
            if (res.status === 'success' && res.data) {
                commit('setCategories', res.data);
            }
        } catch (e) {
            console.error(e);
        }
    },
    async getMenuSite({ commit }) {
        try {
            const res = await this.$axios.$get(`/api/v1/menu/?area=2`, { cache: true });
            if (res.status === 'success' && res.data) {
                commit('setMenuSite', res.data);
            }
        } catch (e) {
            console.error(e);
        }
    },
    async getMenuFooter({ commit }) {
        try {
            const res = await this.$axios.$get(`/api/v1/menu/?area=3`, { cache: true });
            if (res.status === 'success' && res.data) {
                commit('setMenuFooter', res.data);
            }
        } catch (e) {
            console.error(e);
        }
    },
    async getMenuShowcase({commit, state}) {
        try {
            const res = await this.$axios.$get(`/?p=ecommerce_produtos_showcases_areas_api_v1&area=${state.config.modules.showcase.menu_area}`, { cache: true });
            if (res.status === 'success' && res.data) {
                commit('setMenuShowcase', res.data);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
